import { template as template_66c0b9ef8b9641bdb2eb14fc139dcd37 } from "@ember/template-compiler";
const FKControlMenuContainer = template_66c0b9ef8b9641bdb2eb14fc139dcd37(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
