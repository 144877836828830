import { template as template_aa09dda0508b4f9eae2fcc22f50e781c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_aa09dda0508b4f9eae2fcc22f50e781c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
