import { template as template_18c527092b3c4bebb1e7d256df97acb2 } from "@ember/template-compiler";
const FKText = template_18c527092b3c4bebb1e7d256df97acb2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
